import { endOfDay, parse, startOfDay } from 'date-fns';
import cloneDeep from 'lodash.clonedeep';
import isEmpty from 'lodash.isempty';

export const filtersWithValues = (filters) => {
  return filters.filter((item) => {
    return !isEmpty(item.value?.toString());
  });
};

export const activeFiltersCount = (filters) => {
  if (!hasActiveFilters(filters)) {
    return 0;
  }

  return filtersWithValues(filters).length;
};
export const formatFiltersForServer = (filters) => {
  const _filters = cloneDeep(filters);

  // get only filters which has values
  // format specific filter types (e.g. date) before send them to the server
  if (!_filters?.length) {
    return [];
  }
  const filtersWithValues = _filters.filter((item) => {
    return !isEmpty(item.value?.toString()); //!!item.value && item.value?.length
  });
  filtersWithValues.forEach((item) => {
    if (item.type == 'vendors') {
      item.value = item.value._id;
    }

    if (item.type === 'users') {
      item.value = item.value._id;
    }

    if (item.operator) {
      switch (item.operator) {
        case 'ge':
          item.value = { $gte: item.value };
          break;
        case 'le':
          item.value = { $lte: item.value };
          break;
        case 'gt':
          item.value = { $gt: item.value };
          break;
        case 'lt':
          item.value = { $lt: item.value };
          break;
        case 'in':
          item.value = { $in: item.value };
          break;
        case 'ne':
          item.value = { $ne: item.value };
          break;
        case 'bt':
          if (item.value?.length === 2) {
            const from = startOfDay(
              parse(item.value[0], 'yyyy-MM-dd', new Date())
            );
            const to = endOfDay(parse(item.value[1], 'yyyy-MM-dd', new Date()));
            item.value = { $gte: from, $lte: to };
          } else if (item.value?.length === 1) {
            item.value = { $gte: item.value[0] };
          }
          break;
      }
    }
  });

  return filtersWithValues;
};

export const getFiltersWithValues = (filters) => {
  return filters?.filter((item) => {
    if (Array.isArray(item.value)) {
      return item.value.length > 0;
    } else {
      return !!item.value;
    }
  });
};

export const hasActiveFilters = (filters) => {
  return getFiltersWithValues(filters)?.length > 0;
};
